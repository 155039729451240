//NavBar.vue
<template>
  <nav :class="{ 'scrolled': hasScrolled }">
    <div class="nav-container">
      <div class="logo">BarIQ</div>
      
      <!-- Menu Burger pour Mobile -->
      <div class="burger-menu" @click="toggleMenu">
        <div class="burger-icon" :class="{ 'active': isMenuOpen }">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <!-- Menu Links -->
      <div class="nav-links" :class="{ 'active': isMenuOpen }">
        <a href="#features" @click="closeMenu(); scrollToFeatures()">Promesses</a>
        <a href="#solutions" @click="closeMenu(); scrollToFunction()">Fonctionnalités</a>
        <button class="demo-btn" @click="closeMenu(); scrollToContact()">Nous contacter</button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      isMenuOpen: false
    }
  },
  methods: {
    scrollToFeatures() {
        document.querySelector('.why-us').scrollIntoView({behavior: 'smooth'})
    },
    scrollToFunction() {
        document.querySelector('.feature-card').scrollIntoView({behavior: 'smooth'})
    },
    scrollToContact() {
        document.querySelector('.contact-form').scrollIntoView({behavior: 'smooth'})
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
      document.body.style.overflow = this.isMenuOpen ? 'hidden' : ''
    },
    closeMenu() {
      this.isMenuOpen = false
      document.body.style.overflow = ''
    }
  }
}
</script>

<style scoped>
nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 1rem 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

nav.scrolled {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.8rem;
  font-weight: 700;
  background: linear-gradient(135deg, #ff6b6b, #ffd93d);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.nav-links a {
  text-decoration: none;
  color: #2d3436;
  font-weight: 500;
  font-size: 1rem;
  transition: color 0.3s ease;
  position: relative;
}

.nav-links a::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(135deg, #ff6b6b, #ffd93d);
  transition: width 0.3s ease;
}

.nav-links a:hover::after {
  width: 100%;
}

.demo-btn {
  background: linear-gradient(135deg, #ff6b6b, #ffd93d);
  color: white;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.demo-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 107, 107, 0.3);
}

.burger-menu {
  display: none;
  cursor: pointer;
}

.burger-icon {
  width: 30px;
  height: 20px;
  position: relative;
  z-index: 1001;
}

.burger-icon span {
  display: block;
  width: 100%;
  height: 2px;
  background: #2d3436;
  position: absolute;
  transition: all 0.3s ease;
}

.burger-icon span:first-child {
  top: 0;
}

.burger-icon span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.burger-icon span:last-child {
  bottom: 0;
}

.burger-icon.active span:first-child {
  transform: rotate(45deg);
  top: 50%;
}

.burger-icon.active span:nth-child(2) {
  opacity: 0;
}

.burger-icon.active span:last-child {
  transform: rotate(-45deg);
  bottom: 40%;
}

@media (max-width: 968px) {
  .burger-menu {
    display: block;
  }

  .nav-links {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background: white;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    transition: right 0.3s ease;
    padding: 2rem;
  }

  .nav-links.active {
    right: 0;
  }

  .nav-links a {
    font-size: 1.2rem;
  }

  .demo-btn {
    width: 200px;
    text-align: center;
    padding: 1rem;
  }
}
</style>