<template>
  <div class="home-view">
    <!-- Le contenu de votre page d'accueil -->
  </div>
</template>

<script>
export default {
  name: 'HomeView'  // Nom modifié en multi-mots
}
</script>