<template>
  <div>
    <nav-bar />
    <div class="container">
      <hero-section />
      <WhyUs/>
      <section class="features">
        <feature-card
          v-for="feature in features"
          :key="feature.id"
          :icon="feature.icon"
          :title="feature.title"
          :description="feature.description"
        />
      </section>
      <platform-icons />
      <contact-form />
    </div>
  </div>
</template>

<script>
import NavBar from './components/Navbar.vue'
import WhyUs from './components/WhyUs.vue'
import HeroSection from './components/HeroSection.vue'
import FeatureCard from './components/FeatureCard.vue'
import ContactForm from './components/ContactForm.vue'
import PlatformIcons from './components/PlatformIcons.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    HeroSection,
    WhyUs,
    FeatureCard,
    ContactForm,
    PlatformIcons
  },
  data() {
    return {
      features: [
        {
          id: 1,
          icon: '<rect width="50" height="50" fill="#ff6b6b" rx="10"/><path d="M15 25L22 32L35 18" stroke="white" stroke-width="4" fill="none"/>',
          title: 'Contrôle des stocks',
          description: 'Gestion des stocks alimentée par l\'IA qui anticipe vos besoins avant qu\'ils ne se présentent. Ne manquez plus jamais les produits préférés de vos clients.'
        },
        {
          id: 2,
          icon: '<rect width="50" height="50" fill="#ffd93d" rx="10"/><circle cx="25" cy="25" r="15" stroke="white" stroke-width="4" fill="none"/>',
          title: 'Gestion du personnel',
          description: 'Une gestion des plannings qui s\'ajuste aux moments de forte affluence. Assurez la satisfaction de votre équipe et un service optimal.'
        },
        {
          id: 3,
          icon: '<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"><rect width="50" height="50" fill="#2ecc71" rx="10"/><text x="25" y="35" font-size="35" font-weight="bold" fill="white" text-anchor="middle" font-family="Arial">€</text></svg>',
          title: 'Analytique prédictive',
          description: 'Transformez vos données en profit grâce aux insights alimentés par l\'IA. Comprenez les tendances, optimisez vos prix et boostez vos résultats financiers.'
        },
        {
          id: 4,
          icon: '<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"><rect width="50" height="50" fill="#FFB30F" rx="10"/><path d="M25 10 C17 10 15 16 15 22C15 28 19 31 20 33L30 33C31 31 35 28 35 22C35 16 33 10 25 10Z" fill="white"/><rect x="20" y="33" width="10" height="3" fill="white"/><rect x="21" y="36" width="8" height="3" fill="white"/><rect x="22" y="39" width="6" height="2" fill="white"/><path d="M20 18 Q25 15 30 18" fill="none" stroke="white" stroke-width="1.5"/></svg>',
          title: 'Interface simple',
          description: 'Une interface intuitive, offrant une navigation fluide et des fonctionnalités puissantes pour une gestion simplifiée et efficace de votre établissement.'
        }
      ]
    }
  }
}
</script>

<style>
:root {
  --primary: #2d3436;
  --accent: #ff6b6b;
  --secondary: #ffd93d;
  --tertiary: #4ecdc4;
  --light: #f5f6fa;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

/* Base styles */
body {
  background: var(--light);
  color: var(--primary);
  overflow-x: hidden;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
}

.platform-icons {
  margin-top: -20px; /* Pour rapprocher des feature cards */
}

/* Navigation */
nav {
  background: linear-gradient(135deg, #ff6b6b, #ff8e8e);
  padding: 20px 0;
  box-shadow: 0 2px 15px rgba(255,107,107,0.2);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}

.logo {
  font-size: clamp(20px, 5vw, 24px);
  font-weight: 700;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

/* Hero Section */
.hero {
  margin-top: 100px;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  flex-wrap: wrap;
  padding: 20px 0;
}

.hero-content {
  flex: 1;
  min-width: 300px;
}

.hero-image {
  flex: 1;
  position: relative;
  min-width: 300px;
  display: flex;
  justify-content: center;
}

.hero-image svg {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: clamp(2rem, 5vw, 3.5rem);
  margin-bottom: 20px;
  line-height: 1.2;
  background: linear-gradient(135deg, #ff6b6b, #ffd93d);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

p {
  font-size: clamp(1rem, 2vw, 1.1rem);
  line-height: 1.6;
  color: #636e72;
  margin-bottom: 30px;
}

/* Features Section */
.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin: 50px 0;
  width: 100%;
}

.feature-card {
  background: white;
  padding: clamp(20px, 3vw, 30px);
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  border: 2px solid transparent;
  width: 100%;
}

.feature-card:hover {
  transform: translateY(-5px);
  border-color: var(--accent);
}

.feature-icon {
  width: clamp(40px, 8vw, 50px);
  height: clamp(40px, 8vw, 50px);
  margin-bottom: 20px;
}

/* Contact Form */
.contact-form {
  background: linear-gradient(135deg, white, #fff5f5);
  padding: clamp(20px, 5vw, 40px);
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
  max-width: 600px;
  margin: 50px auto;
  width: 100%;
}

input, textarea {
  width: 100%;
  padding: clamp(8px, 2vw, 12px);
  margin: 10px 0;
  border: 2px solid #ffe0e0;
  border-radius: 8px;
  font-size: clamp(0.9rem, 2vw, 1rem);
  transition: all 0.3s ease;
}

input:focus, textarea:focus {
  border-color: var(--accent);
  outline: none;
}

button {
  background: linear-gradient(135deg, #ff6b6b, #ff8e8e);
  color: white;
  padding: clamp(10px, 2vw, 12px) clamp(20px, 4vw, 30px);
  border: none;
  border-radius: 8px;
  font-size: clamp(0.9rem, 2vw, 1rem);
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 100%;
  max-width: 300px;
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255,107,107,0.3);
}

@keyframes float {
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(5deg); }
  100% { transform: translateY(0px) rotate(0deg); }
}

.floating-graphic {
  animation: float 6s ease-in-out infinite;
  width: 100%;
  max-width: 400px;
}

/* Media Queries */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }

  .hero-content, .hero-image {
    flex: none;
    width: 100%;
  }

  button {
    width: 100%;
    margin: 10px 0;
  }

  .features {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 15px;
  }

  .feature-card {
    padding: 20px;
  }

  .contact-form {
    padding: 20px;
  }
}

/* Ajouts pour améliorer l'accessibilité sur mobile */
@media (hover: none) {
  .feature-card:hover {
    transform: none;
  }

  button:hover {
    transform: none;
  }
}

/* Pour les grands écrans */
@media (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }

  .hero {
    gap: 100px;
  }
}

/* Pour les écrans très petits */
@media (max-width: 320px) {
  .hero {
    margin-top: 80px;
  }

  h1 {
    font-size: 1.8rem;
  }

  p {
    font-size: 0.9rem;
  }
}
</style>