<template>
  <div class="feature-card">
    <svg class="feature-icon" viewBox="0 0 50 50" v-html="icon"></svg>
    <h3>{{ title }}</h3>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'FeatureCard',
  props: {
    icon: String,
    title: String,
    description: String
  }
}
</script>