//WhyUsSection.vue
<template>
  <section class="why-us">
    <h2 class="title">Pourquoi nous ?</h2>
    <div class="stats-container">
      <div class="stat-item" v-for="stat in stats" :key="stat.number">
        <div class="stat-number">{{ stat.number }}</div>
        <div class="stat-label">{{ stat.label }}</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhyUsSection',
  data() {
    return {
      stats: [
        {
          number: '+45%',
          label: 'de productivité'
        },
        {
          number: '-30%',
          label: 'de pertes de stock'
        },
        {
          number: '24/7',
          label: 'support client'
        },
        {
          number: '+1000',
          label: 'bars nous font confiance'
        }
      ]
    }
  }
}
</script>

<style scoped>
.why-us {
  padding: 4rem 0;
  border-radius: 15px;
  margin-bottom: 2rem;
}

.title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: var(--primary);
  background: linear-gradient(135deg, #ff6b6b, #ffd93d);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.stats-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0 2rem;
}

.stat-item {
  text-align: center;
  min-width: 200px;
  padding: 1.5rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-5px);
  border-color: #ff6b6b;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--accent);
  margin-bottom: 0.5rem;
  background: linear-gradient(135deg, #ff6b6b, #ffd93d);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat-label {
  font-size: 1.1rem;
  color: #636e72;
  font-weight: 500;
}

@media (max-width: 768px) {
  .stats-container {
    gap: 1.5rem;
  }

  .stat-item {
    min-width: 160px;
    padding: 1.2rem;
  }

  .stat-number {
    font-size: 2rem;
  }

  .stat-label {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .why-us {
    padding: 3rem 0;
  }

  .title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .stats-container {
    padding: 0 1rem;
  }

  .stat-item {
    width: calc(50% - 1rem);
    min-width: unset;
  }
}
</style>