<template>
  <section class="contact-form">
    <h2>Envie d'un coup de boost ?</h2>
    <p>Contactez-nous dès maintenant et obtenez plus d'informations.</p>
    
    <!-- Message de succès ou d'erreur -->
    <div v-if="submitStatus.message" 
         :class="['status-message', submitStatus.type]">
      {{ submitStatus.message }}
    </div>

    <form @submit.prevent="handleSubmit" :class="{ 'loading': isLoading }">
      <div class="form-group">
        <input v-model="formData.name" 
               type="text" 
               placeholder="Votre nom" 
               required
               :disabled="isLoading">
        <span class="error-message" v-if="validationErrors.name">{{ validationErrors.name }}</span>
      </div>

      <div class="form-group">
        <input v-model="formData.email" 
               type="email" 
               placeholder="Votre email" 
               required
               :disabled="isLoading">
        <span class="error-message" v-if="validationErrors.email">{{ validationErrors.email }}</span>
      </div>

      <div class="form-group">
        <input v-model="formData.barName" 
               type="text" 
               placeholder="Nom de votre bar"
               :disabled="isLoading">
      </div>

      <div class="form-group">
        <textarea v-model="formData.message" 
                  placeholder="Parlez-nous de votre bar et de vos objectifs." 
                  rows="4"
                  required
                  :disabled="isLoading">
        </textarea>
        <span class="error-message" v-if="validationErrors.message">{{ validationErrors.message }}</span>
      </div>

      <button type="submit" :disabled="isLoading || !isFormValid">
        {{ isLoading ? 'Envoi en cours...' : 'Envoyer' }}
      </button>
    </form>
  </section>
</template>

<script>
const API_URL = 'https://bariq.fr/api/contacts';

export default {
  name: 'ContactForm',
  data() {
    return {
      formData: {
        name: '',
        email: '',
        barName: '',
        message: ''
      },
      validationErrors: {
        name: '',
        email: '',
        message: ''
      },
      isLoading: false,
      submitStatus: {
        message: '',
        type: ''
      }
    }
  },
  computed: {
    isFormValid() {
      return this.formData.name && 
             this.formData.email && 
             this.formData.message && 
             this.validateEmail(this.formData.email);
    }
  },
  methods: {
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    validateForm() {
      let isValid = true;
      this.validationErrors = {
        name: '',
        email: '',
        message: ''
      };

      if (!this.formData.name) {
        this.validationErrors.name = 'Le nom est requis';
        isValid = false;
      }

      if (!this.formData.email) {
        this.validationErrors.email = 'L\'email est requis';
        isValid = false;
      } else if (!this.validateEmail(this.formData.email)) {
        this.validationErrors.email = 'L\'email n\'est pas valide';
        isValid = false;
      }

      if (!this.formData.message) {
        this.validationErrors.message = 'Le message est requis';
        isValid = false;
      }

      return isValid;
    },
    async handleSubmit() {
      if (!this.validateForm()) return;

      this.isLoading = true;
      this.submitStatus = { message: '', type: '' };

      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.formData)
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Une erreur est survenue');
        }

        // Succès
        this.submitStatus = {
          message: 'Votre message a été envoyé avec succès !',
          type: 'success'
        };

        // Réinitialiser le formulaire
        this.formData = {
          name: '',
          email: '',
          barName: '',
          message: ''
        };

      } catch (error) {
        this.submitStatus = {
          message: error.message || 'Une erreur est survenue lors de l\'envoi',
          type: 'error'
        };
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped>
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
}

.form-group {
  margin-bottom: 1rem;
}

form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

input, textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

input:focus, textarea:focus {
  outline: none;
  border-color: #007bff;
}

button {
  padding: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s;
}

button:hover:not(:disabled) {
  background-color: #0056b3;
  transform: translateY(-1px);
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: none;
}

.status-message {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  text-align: center;
}

.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.loading {
  opacity: 0.7;
  pointer-events: none;
}

@media (max-width: 768px) {
  .contact-form {
    padding: 1rem;
  }
}
</style>