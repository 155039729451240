<template>
  <div class="platform-icons">
    <h2>Bientôt disponible sur</h2>
    <div class="icons-container">
      <a class="platform-link" v-for="platform in platforms" :key="platform.name">
        <div class="icon-wrapper">
          <div class="icon" v-html="platform.icon"></div>
          <span>{{ platform.name }}</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlatformIcons',
  data() {
    return {
      platforms: [
        { 
          name: 'Windows',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88" width="32" height="32">
                  <path d="m0 12.402 35.687-4.86.016 34.423-35.67.203zm35.67 33.529.028 34.453L.028 75.48.026 45.7zm4.326-39.025L87.314 0v41.527l-47.318.376zm47.329 39.349-.011 41.34-47.318-6.678-.066-34.739z" 
                        fill="#00a4ef"/>
                </svg>`,
          class: 'windows'
        },
        { 
          name: 'MacOS',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="32" height="32">
                  <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                        fill="#555555"/>
                </svg>`,
          class: 'apple'
        },
        { 
          name: 'Android',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 505 505" width="32" height="32">
                  <path d="m374.1 267.3h-243.2c-4.9 0-8.8 4-8.8 8.8v115.7c0 4.9 4 8.8 8.8 8.8h35.8v69.9c0 19.2 15.6 34.5 34.5 34.5s34.5-15.6 34.5-34.5v-69.9h33.5v69.9c0 19.2 15.6 34.5 34.5 34.5s34.5-15.6 34.5-34.5v-69.9h35.8c4.9 0 8.8-4 8.8-8.8v-115.7c.1-4.8-3.9-8.8-8.7-8.8zm-252-9.3c0-19.2-15.6-34.5-34.5-34.5s-34.5 15.6-34.5 34.5v92.1c0 19.2 15.6 34.5 34.5 34.5s34.5-15.6 34.5-34.5zm451.9 0c0-19.2-15.6-34.5-34.5-34.5s-34.5 15.6-34.5 34.5v92.1c0 19.2 15.6 34.5 34.5 34.5s34.5-15.6 34.5-34.5zm-271.7-159.9-29.9-53.5c-1.9-3.5-.7-7.8 2.7-9.8 3.5-1.9 7.8-.7 9.8 2.7l30.3 54.1c25.5-11.4 54.1-17.8 84.2-17.8 30.2 0 58.7 6.4 84.2 17.8l30.3-54.1c1.9-3.5 6.3-4.7 9.8-2.7 3.5 1.9 4.7 6.3 2.7 9.8l-29.9 53.5c58.2 29.7 97.5 89.1 97.5 157.2 0 2.9-.1 5.8-.2 8.7h-388.5c-.1-2.9-.2-5.8-.2-8.7 0-68.1 39.3-127.5 97.2-157.2zm-47-16c10.1 0 18.3-8.2 18.3-18.3s-8.2-18.3-18.3-18.3-18.3 8.2-18.3 18.3 8.2 18.3 18.3 18.3zm226.8 0c10.1 0 18.3-8.2 18.3-18.3s-8.2-18.3-18.3-18.3-18.3 8.2-18.3 18.3 8.3 18.3 18.3 18.3z"
                        fill="#3ddc84"/>
                </svg>`,
          class: 'android'
        },
        { 
          name: 'iOS',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="32" height="32">
                  <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                        fill="#000000"/>
                </svg>`,
          class: 'ios'
        }
      ]
    }
  }
}
</script>

<style scoped>
.platform-icons {
  width: 100%;
  padding: 2rem 0;
  margin-bottom: 2rem;
  border-radius: 15px;
  text-align: center;
}

.icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 2.5rem;
  flex-wrap: wrap;
  padding: 0 1rem;
}

.platform-link {
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
}

.platform-link:hover {
  transform: translateY(-5px);
}

.platform-link:hover .icon {
  filter: brightness(1.2);
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
}

.icon {
  width: 32px;
  height: 32px;
  transition: all 0.3s ease;
}

.icon-wrapper span {
  font-size: 1.2rem;
  font-weight: 500;
  color: #4B5563;
}

@media (max-width: 768px) {
  .icons-container {
    gap: 2rem;
  }
  
  .icon-wrapper span {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .platform-icons {
    padding: 1.5rem 0;
  }
  
  .icons-container {
    gap: 1.5rem;
    grid-template-columns: repeat(3, 1fr);
  }

  .icon {
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 360px) {
  .icons-container {
    gap: 1rem;
  }
}
</style>