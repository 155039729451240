<template>
    <section class="hero">
      <div class="hero-content">
        <h1>Votre gestionnaire intelligent</h1>
        <p>Révolutionnez la gestion de votre bar ou club grâce à une solution alimentée par l'IA. Suivez les stocks, gérez le personnel et augmentez vos profits grâce à des analyses intelligentes et des données en temps réel.</p>
        <p>Une solution tout-en-un pour optimiser la gestion de votre établissement</p>
        <button @click="scrollToContact">Obtenir plus d'informations</button>
      </div>
      <div class="hero-image">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
        <!-- Groupe pour tout le verre -->
        <g>
          <animateTransform
            attributeName="transform"
            type="translate"
            values="-20;20;-20"
            dur="4s"
            repeatCount="indefinite"
            additive="sum"/>

          <!-- Verre à cocktail -->
          <path d="M160 320 L240 320 L280 120 L120 120 Z" fill="rgba(255,255,255,0.2)" stroke="#ff6b6b" stroke-width="4"/>
          
          <!-- Pied du verre -->
          <path d="M180 320 L220 320 L220 380 L180 380 Z" fill="#ff6b6b"/>
          
          <!-- Base du verre -->
          <path d="M160 380 L240 380 L240 390 L160 390 Z" fill="#ff6b6b"/>
          
          <!-- Liquide -->
          <path d="M126 140 L274 140 L239 320 L161 320 Z" fill="#ff8e8e"/>
          
          <!-- Groupe pour le parasol avec point de pivot -->
          <g transform-origin="180 140">
            <animateTransform
              attributeName="transform"
              type="rotate"
              values="-5;5;-5"
              dur="3s"
              repeatCount="indefinite"
              additive="sum"/>
            
            <!-- Tige du parasol -->
            <line x1="180" y1="100" x2="180" y2="200" stroke="#4ecdc4" stroke-width="4"/>

            <!-- Parasol -->
            <path d="M180 40 L220 100 L140 100 Z"  fill="#ffd93d"/>
          </g>
          
          <!-- Reflets -->
          <path d="M140 140 L150 140 L140 180 Z" fill="rgba(255,255,255,0.3)"/>
          
          <!-- Décoration -->
          <circle cx="190" cy="170" r="5" fill="#FF4757"/>
          
          <!-- Paille -->
          <path d="M240 80 L200 200" stroke="#4ecdc4" stroke-width="6" stroke-linecap="round"/>
          
          <!-- Bulles -->
          <circle cx="180" cy="200" r="4" fill="rgba(255,255,255,0.6)">
            <animate attributeName="cy" from="200" to="150" dur="2s" repeatCount="indefinite"/>
          </circle>
          <circle cx="190" cy="220" r="3" fill="rgba(255,255,255,0.6)">
            <animate attributeName="cy" from="220" to="170" dur="2.5s" repeatCount="indefinite"/>
          </circle>
          <circle cx="200" cy="210" r="2" fill="rgba(255,255,255,0.6)">
            <animate attributeName="cy" from="210" to="160" dur="1.8s" repeatCount="indefinite"/>
          </circle>
          
          <!-- Ombrages -->
          <path d="M130 140 Q200 160 270 140" fill="none" stroke="rgba(0,0,0,0.1)" stroke-width="2"/>
        </g>
      </svg>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'HeroSection',
    methods: {
      scrollToContact() {
        document.querySelector('.contact-form').scrollIntoView({behavior: 'smooth'})
      }
    }
  }
  </script>